import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import fwLogo from "../assets/fastway-logo.svg"
import eqLogo from "../assets/equalizer-logo.svg"
import { Container, Row, Col } from "reactstrap"
import styles from "../pages/css/index.module.css"

export default ({data}) => {
  const content = data.craft.content[0];
  return (
    <div>
      <Header/>
      <div className={styles.hero}>
        <Container>
          <Row>
            <Col xs="12" md="8">
              <div className={styles.heroContent}>
                <h1>Thank You</h1>
                <div dangerouslySetInnerHTML={{ __html: content.aboveFoldContent }}></div>
              </div>
              <div className={styles.heroLogos}>
                <ul>
                  <li><a href="https://www.equalizerhitch.com"><img src={eqLogo} alt="Equal-i-zer Logo"/></a></li>
                  <li><a href="https://www.fastwaytrailer.com"><img src={fwLogo} alt="Fastay Trailer Logo"/></a></li>
                </ul>
              </div>
            </Col>
            <Col xs="12" md="4">
              <aside className={styles.sideBar}>
                  <div className={styles.sideBarHeader}>What do I need to do?</div>
                  <div className={styles.sideBarContent}>
                      <ol>
                          {content.whatDoINeedToDoSteps.map((step, index) =>
                          <li key={index} className={styles.sideBarContentListItem} dangerouslySetInnerHTML={{ __html: step.stepDescription }}></li>
                          )}
                      </ol>
                  </div>
              </aside>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  )
}

export const query = graphql`
  query ThankYouPage {
    craft {
      content: entries(siteId: "4", slug: "bpp-home", status: "disabled") {
        ... on craft_bppHome_bppHome_Entry {
          id
          aboveFoldHeadline
          aboveFoldContent
          whatDoINeedToDoSteps {
            ... on craft_whatDoINeedToDoSteps_step_BlockType {
              stepDescription
            }
          }
          mainContentHeadline
          mainContent
        }
      }
    }
  }
`;